import React, { Component } from "react";
import './assets/css/bootstrap.min.css'
import './assets/css/bulma.min.css'
import boxes from './assets/data/boxes.json'
import cornerLogo from './assets/images/cornerLogo.png'

import { Switch, Route } from 'react-router-dom';

import './App.css';

import aboutLogoDesktop from './assets/images/aboutLogoDesktop.png'
import aboutLogo from './assets/images/aboutLogo.png'
import galleryImage from './assets/images/beans-close-up-cuisine-16407712.jpg'

const App = () => (
  <div >
    <Navbar />
    <Main />
    <Footer />
  </div>
);

class ProductCard extends Component {
  quantity(q) {
    if (q != "") {
      return  <h1 style={{fontSize: "11px", marginBottom: "10px"}}> 
                {q} / Case
              </h1>
    }
    return
  }

  size(n, s) {
    if (s != "") {
      return <h3 className="title" style={{marginBottom: "5px"}}>
                {n} | {s}
            </h3>
    }

    return <h3 className="title" style={{marginBottom: "5px"}}>
              {n}
          </h3>
  }

  render() {
    const image = require("" + this.props.filename);

    
    return (
      <div className="col-md-3 col-sm-6" style={{marginBottom: "20px"}}>
        <div className="product-grid4">

          <div className="product-image4" style={{paddingTop: "10px"}}>
            <img className="" alt="Product" src={image}
                                                style={{width: "auto", height: "160px"}}/>
                                        </div>
          <div className="product-content">
            {this.size(this.props.name, this.props.size)}
            {this.quantity(this.props.quantity)}
            <div className="title" style={{marginBottom: "5px", height: "10px"}}> </div>
      </div>
    </div >
  </div >
    );
  }
}
class ProductList extends Component {
  render() {
    var products = []
    var i;
    var box;

    for (i = 0; i < boxes.length; i++) {
      box = boxes[i];
      if (box["ContainerType"] !== this.props.match.params.id) { continue }

      box["filename"] = "./assets/images/" + box["Name"].toLowerCase().replace("*", "") + ".png"

      products.push(<ProductCard filename={box["filename"]} name={box["Name"]} size={box["Size"]} quantity={box["Quantity"]} key={i}/>)
    } 

    return (
      <div className='hero-body product-list' style={{paddingTop: "30px"}}>
        <br />
        <div className="shop-wrapper">
          <div className="section padded-section">
            <div className="container">

              <div className="columns category-header">
                <div className="column is-10 is-offset-1 is-tablet-landscape-padded">
                  <div className="category-title is-product-category">
                    <h2 className="brand-name">{this.props.match.params.id}</h2>
                    <hr />
                    <div className="row">
                      {products}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Product extends Component {
  render() {
    return (
      <div className="col-md-3 col-sm-6" style={{ marginBottom: "20px" }} onClick={e => { window.location.href = this.props.link }}>
        <div className="product-grid4">

          <div className="product-image4" style={{ paddingTop: "10px" }}>
            <img alt="Product" src={require("" + this.props.filename)} style={{ width: "auto", height: "160px" }} />
          </div>
          <div className="product-content">
            <h3 className="title" style={{ marginBottom: "5px" }}>
              {this.props.type}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

class Products extends Component {
  render() {
    var productTypes = {}
    var products = []
    var i;
    var box;

    for (i = 0; i < boxes.length; i++) {
      box = boxes[i];
      box["filename"] = "./assets/images/" + box["Name"].toLowerCase().replace("*", "") + ".png"
      box["type"] = box["ContainerType"]
      box["link"] = "/products/" + box["ContainerType"]

      console.log(productTypes)
      if (productTypes[box["link"]]) { continue }
      console.log(box)
      

      productTypes[box["link"]] = box["filename"]
      products.push(<Product link={box["link"]} filename={box["filename"]} type={box["type"]} key={i} />)
    }
    return (
      <div className='hero-body product-list'>
        <div className="shop-wrapper">
          <div className="section padded-section">
            <div className="container">
              <div className="columns category-header">
                <div className="column is-10 is-offset-1 is-tablet-landscape-padded">
                  <div className="category-title is-product-category">
                    <h2 className="brand-name">Products</h2>
                    <hr />

                    <div className="columns is-product-grid is-multiline">
                      {products}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <br/>
      </div>
      
    );
  }
}

const Gallery = () => (
  <section className="hero is-medium resize-banner" style={{ paddingTop: "50px" }}>
    <div className="hero has-background box-image">
      <figure className="image banner-image">
        <img alt="Gallery" className="hero-background is-transparent banner-image" src={galleryImage}/>
      </figure>
      <div className="image-text">
        <div className="title is-3 is-space inner-text">
          A Plastic Container Supplier
            </div>
        <div className="subtitle is-5 is-mited inner-text">
          Free Delivery
            </div>
      </div>
    </div>

  </section>
)
const Home = () => (
  <div>
    <Gallery />
    <Products />
  </div>
);

const Footer = () => (
  <footer className="footer" style={{ margin: "0 auto", marginTop: "10px", backgroundColor: "white" }}>
    <div className="content has-text-centered">
      <p>
        <strong>Order By Phone At: </strong> <a href="tel:+1-720-938-2788">720-938-2788</a>
                </p>
    </div>
  </footer>
)

class Navbar extends Component {
  render () {
    document.addEventListener('DOMContentLoaded', () => {

      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {

          // Add a click event on each of them
          $navbarBurgers.forEach(el => {
              el.addEventListener('click', () => {

                  // Get the target from the "data-target" attribute
                  const target = el.dataset.target;
                  const $target = document.getElementById(target);

                  // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                  el.classList.toggle('is-active');
                  $target.classList.toggle('is-active');

              });
          });
      }

  });
    return (
  <div className="hero-head" style={{ paddingBottom: "20px" }}>
    <nav id="navbar-menu" className="navbar is-fixed-top" style={{ padding: "1rem .9rem" }}>
      <div className="container" style={{ padding: "0rem 0rem" }}>
        <div className="navbar-brand" >
          <a href="/" className="navbar-item" style={{ paddingLeft: "10px", paddingRight: "5px", marginRight: "auto" }}>
            <img alt="Logo" className="logo-image" src={cornerLogo}/>
            <span className="brand-name logo-font">Trading Company </span>
          </a>
         <a role="button" className="navbar-burger burger navbar-brand" aria-label="menu" aria-expanded="false"
                    data-target="pages">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
        </a>
        </div>


        <div id="pages" className="navbar-menu navbar-brand">
          <div className="navbar-start" style={{ marginRight: "initial" }}>
            <a href="/" className="navbar-item">
              Home
                    </a>
          </div>
          <div className="navbar-start" style={{ marginRight: "initial" }}>
            <a href="/about" className="navbar-item">
              About
                    </a>
          </div>
          <div className="navbar-start" style={{ marginRight: "initial" }}>
            <a href="/contact" className="navbar-item">
              Contact
                    </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
      );
    }
  }

const About = () => (
  <div className="hero" style={{ padding: "4rem 3rem", maxWidth: "1000px", margin: "0 auto" }}>
    <br />
    <picture>
      <source
        className="about-image"
        media="(min-width:650px)"
        srcSet={aboutLogoDesktop} />
      <source
        className="about-image"
        media="(max-width:649px"
        srcSet={aboutLogo} />
      <img
        alt="About Logo"
        src={aboutLogo}
        className="about-image" />
    </picture>
    <hr />
    <h2 className="brand-name center" style={{ fontSize: "60px" }}>
      Our Promise
      </h2>

    <span className="center about-span">
      <p className="center about-text">
        We believe in growth through great service and long-term partnerships. We strive to exceed customer
        expectations and deliver personalized service to each of our valued business partners.
          </p>

      <p className="center about-text">
        <span>​</span>
      </p>

      <p className="center about-text">
        At C.L. Trading Company, we believe if we make a claim that food packaging is better for the environment,
        that it really should be. All of our containers are fully recyclable and reusable.
          </p>

      <p className="center about-text">
        <span>​</span>
      </p>

      <p className="center about-text subtitle">
        Our dedication to globally sourcing&nbsp;only the best products in our industry is only out done by our
        desire to provide the most competitive pricing possible.
          </p>
    </span>
  </div>
);

const Contact = () => (
  <div className="hero" style={{ paddingTop: "100px", padding: "4rem 3rem", maxWidth: "1000px", margin: "0 auto", display: "inherit" }}>
    <br />
    <picture>
      <source
        className="about-image"
        media="(min-width:650px)"
        srcset={aboutLogoDesktop} />
      <source
        className="about-image"
        media="(max-width:649px"
        srcset={aboutLogo} />
      <img
        alt="About Logo"
        src={aboutLogo}
        className="about-image" />
    </picture>
    <hr />

    <div>
      <h2 className="brand-name center" style={{ paddingBottom: "10px" }}> Where To Find Us </h2>
      <h5 className="center">3899 Jackson St Unit 5E </h5>
      <h5 className="center">Denver, Colorado </h5>
      <h5 className="center">cltradingcompanyllc@yahoo.com</h5>
      <h5 className="center">Tel: 720-938-2788 </h5>
    </div>

    <div className="map-responsive">

      <iframe title="Map" width="1000" height="1000" id="gmap_canvas"
        src="https://maps.google.com/maps?q=3899%20Jason%20Street%2C%20Denver%2C%20CO&t=&z=15&ie=UTF8&iwloc=&output=embed"
        frameborder="0" scrolling="no"></iframe>

    </div>
  </div>
);

const Main = () => (
  <Switch>
    <Route exact path='/' component={Home}></Route>
    <Route exact path='/about' component={About}></Route>
    <Route exact path='/contact' component={Contact}></Route>
    <Route exact path="/products/:id" component={ProductList}></Route>
  </Switch>
);

export default App;